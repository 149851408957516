import request from "@/utils/request";

// 登录
export function signin(data) {
  return request.post("web/v1/signin", data);
}
// 注册
export function signup(data) {
  return request.post("web/v1/signup", data);
}
// 获取手机验证码
export function phoneCode(data) {
  return request.post("web/v1/phone/verify/code", data);
}

// 退出登录
export function signout(data) {
  return request.post("web/v1/signout", data);
}

// 获取个人信息
export function user(params) {
  return request.get("web/v1/user", { params });
}

//图形验证码
export function verifyCode(data) {
  return request.post("web/v1/image/verify/code", data);
}

// 谷歌登录授权
export function googleLogin(params) {
  return request.get("google/callback", { params });
}
