import { createI18n } from "vue-i18n"; //引入vue-i18n
import en from "./en";
import vi from "./vi";
import hi from "./hi";
import pt_br from "./pt_br";
import es_mx from "./es_mx";
import ko from "./ko";
import ja from "./ja";
const messages = {
  en: { ...en },
  vi: { ...vi },
  hi: { ...hi },
  pt_br: { ...pt_br },
  es_mx: { ...es_mx },
  ja: { ...ja },
  ko: { ...ko },
};

const i18n = createI18n({
  locale: sessionStorage.getItem("locale") || "hi", // 语言标识
  messages,
});

export default i18n;
