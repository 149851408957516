export default {
  message: {
    withdrawRecord: "Withdrawal Record",
    rechargeRecord: "Recharge Record",
    topUp: "TOP UP",
    withdrawDeposit: "Withdrawal Deposit",
    quit: "Quit",
    myName: "My Name",
    rechargeAmount: "recharge amount",
    channelDisbursement: "channel of disbursement",
    cashAmount: "Cash withdrawal amount",
    bankInformation: "Bank information",
    amend: "Amend",
    commission: "Withdrawal commission",
    bankName: "Bank Name",
    bankCard: "Bank Card",
    bank: "Bank card information",
    account: "Account",
    bankCardName: "Bank card name",
    cardNo: "CardNo",
    email: "Email",
    firstName: "First Name",
    lastName: "Last Name",
    name: "Name",
    phone: "Phone",
    upi: "Upi",
    ok: "Ok",
    enterAccount: "Enter Account",
    enterCardName: "Enter Card Name",
    enterCardNo: "Enter Card No",
    enterEmail: "Enter Email",
    enterFirstName: "Enter First Name",
    enterLastName: "Enter Last Name",
    enterName: "Enter Name",
    enterPhone: "PLease enter your phone number",
    inputUpi: "input Upi",
    //
    searchGame: "Search Game",
    title: "All Games",
    paly: "PLAY",
    tips: "Language",
    deposit: "",
    lagout: "Quit",
    login_14: "Two passwords do not match",
    enterGameTips: "Game loading, please do not operate",
    closeGameTips: "Exiting game, please do not operate",
    withdrawal_6: "The withdrawal application was successful",
    withdrawal_99: "Full application",
    withdrawal_0: "To be reviewed",
    withdrawal_1: "To be issued",
    withdrawal_2: "Issued",
    withdrawal_3: "Provisioning failure",
    withdrawal_4: "return to the original way",
    withdrawal_5: "close",
    pay_0: "all orders",
    pay_1: "during the payment",
    pay_2: "payment success",
    pay_3: "payment failure",
    pay_4: "Timeout close",
    failed: "Loading failed",
    googleLoginTips: "Logging in",
    popular: "Popular Games",
    recommendation: "Recommended Games",
  },
};
