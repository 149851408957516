export default {
  message: {
    title: "すべてのゲーム",
    paly: "あそぶ",
    login_14: "2 つのパスワードが一致しません",
    enterGameTips: "ゲームロード中、操作しないでください",
    closeGameTips: "ゲームを終了しています。操作しないでください",
    withdrawal_6: "出金申請が成功しました",
    withdrawal_99: "完全なアプリケーション",
    withdrawal_0: "見直し予定",
    withdrawal_1: "発行予定",
    withdrawal_2: "発行済み",
    withdrawal_3: "プロビジョニングの失敗",
    withdrawal_4: "元の道に戻る",
    withdrawal_5: "近い",
    pay_0: "すべての注文",
    pay_1: "支払い中",
    pay_2: "支払い成功",
    pay_3: "支払い失敗",
    pay_4: "タイムアウト終了",
    failed: "ロードに失敗しました",
    googleLoginTips: "ログイン中",
    popular: "人気ゲーム",
    recommendation: "おすすめのゲーム",
  },
};
