<template>
  <div class="home">
    <div class="bg-home" :style="{ height: bgHeight + 'px' }">
      <van-sticky @scroll="stickyTop">
        <div class="tab-fixed-head">
          <!-- 功能模块 -->
          <div class="flex-center justify-between nav-index">
            <div class="flex-center">
              <img class="icon-60 brd-50" :src="userInfo.head_img_url" alt="" />
              <div class="info">
                <span class="white" v-if="userInfo.nickname">{{
                  userInfo.nickname
                }}</span>
                <span class="white" v-else>{{ $t("message.myName") }}</span>
                <div class="flex-center money">
                  <img
                    class="icon-16"
                    src="@/assets/image/iconGoldCoins.png"
                    alt=""
                  />
                  <span class="white coin">{{ userInfo.balance }}</span>
                  <img
                    :class="{ rotate: addRotate }"
                    class="icon-20"
                    @click="refresh"
                    src="@/assets/image/refresh.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="flex justify-between">
              <img
                @click="isLogoin"
                class="icon-change icon-36"
                src="@/assets/image/iconChange.png"
                alt=""
              />
              <img
                class="icon-card m-l-20 icon-36"
                src="@/assets/image/iconCard.png"
                alt=""
                @click="clickPop"
              />
            </div>
            <!-- pop -->
            <div v-if="showPopover" class="popModule">
              <div
                class="pop"
                v-for="(item, index) in actions"
                :key="index"
                @click="selectPopover(item)"
              >
                <div class="flex-center pop-tag">
                  <img class="icon-20 m-l-5" :src="item.icon" alt="" />
                  <div class="text">{{ item.text }}</div>
                </div>
                <img
                  v-if="index !== actions.length - 1"
                  class="more-line"
                  src="@/assets/image/more_line.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <!-- tab 切换 -->
          <div>
            <van-tabs
              v-model="tabActive"
              animated
              sticky
              @click-tab="tabChange"
            >
              <van-tab
                v-for="(item, index) in tabList"
                :name="item.label_id"
                :key="index"
                :title="item.label_name"
                color="rgba(255, 255, 255, 0.7)"
              >
              </van-tab>
            </van-tabs>
          </div>
        </div>
      </van-sticky>
    </div>
    <div class="all-in content" ref="homeMain" v-if="tabActive == 0">
      <div>
        <van-search
          class="search"
          v-model="searchValue"
          :placeholder="searchGame"
        >
          <template #left-icon>
            <img
              class="icon-search m-r-5"
              src="@/assets/image/search.png"
              alt=""
            />
          </template>
        </van-search>
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="(item, index) in bannerList" :key="index">
            <img v-lazy="item.url" :src="item.url" />
          </van-swipe-item>
        </van-swipe>
        <div class="swipe-slot m-t-15">
          <div class="flex-start fs16 fw black">
            {{ $t("message.popular") }}
          </div>
          <div class="slide-wrap">
            <div class="tags" v-if="hotGameList && hotGameList.length > 0">
              <div
                class="tag"
                v-for="(item, index) in hotGameList"
                :key="index"
              >
                <img :src="item.icon" alt="" class="icon-60 brd-8" />
                <div class="tag-text ellipsis">{{ item.game_name }}</div>
                <div class="play-btn" @click="openPlayGame(item)">
                  {{ $t("message.paly") }}
                </div>
              </div>
            </div>
            <div v-else class="empty"></div>
          </div>
        </div>
        <div class="swipe-slot m-t-15 m-b-10">
          <div class="flex-start fs16 fw black">
            {{ $t("message.recommendation") }}
          </div>
          <div class="slide-wrap">
            <div
              class="tags"
              v-if="recommendGameList && recommendGameList.length > 0"
            >
              <div
                class="tag"
                v-for="(item, index) in recommendGameList"
                :key="index"
              >
                <img :src="item.icon" alt="" class="icon-60 brd-8" />
                <div class="tag-text ellipsis">{{ item.game_name }}</div>
                <div class="play-btn" @click="openPlayGame(item)">
                  {{ $t("message.paly") }}
                </div>
              </div>
            </div>
            <div v-else class="empty"></div>
          </div>
        </div>
        <div v-for="item in videoList" :key="item.id">
          <video
            class="vivoe-bg brd-8 m-b-10"
            :src="item.url"
            :autoplay="true"
            :loop="true"
            muted
          />
        </div>
      </div>
      <!-- 内容 -->
      <div class="game-main">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            @load="onLoad"
          >
            <div
              v-for="(item, index) in allGameList"
              :key="index"
              class="flex-center bg-white tab-list"
            >
              <img :src="item.icon" alt="" class="lang-img icon-60 brd-8" />
              <div class="flex-center justify-between flex-1 m-l-15 game-warp">
                <div>
                  <div class="large-color">{{ item.game_name }}</div>
                  <div class="size-color">{{ item.game_name }}</div>
                </div>
                <div class="play-btn" @click="openPlayGame(item)">
                  {{ $t("message.paly") }}
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <!-- tab内容 -->
    <div class="other-tab" ref="homeMain" v-else-if="tabActive !== 0">
      <div
        class="bg-white content tab-main"
        :class="labelGameList && labelGameList.length > 0 ? 'exitMain' : ''"
        v-if="labelGameList && labelGameList.length > 0"
      >
        <van-pull-refresh v-model="labelRefreshing" @refresh="onRefreshLabel">
          <van-list
            v-model="labelLoading"
            :finished="labelFinished"
            @load="onLoadLabel"
          >
            <div
              v-for="(item, index) in labelGameList"
              :key="index"
              class="flex-center bg-white tab-list"
            >
              <img :src="item.icon" alt="" class="lang-img icon-60 brd-8" />
              <div class="flex-center justify-between flex-1 m-l-15 game-warp">
                <div>
                  <div class="large-color black">{{ item.game_name }}</div>
                  <div class="size-color">{{ item.game_name }}</div>
                </div>
                <div class="play-btn" @click="openPlayGame(item)">
                  {{ $t("message.paly") }}
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <!-- 语言遮罩层 -->
    <van-overlay :show="languageIsShow" @click="languageIsShow = false">
      <div class="wrapper">
        <div class="block">
          <van-radio-group
            v-model="chosenLangId"
            @change="changeLang"
            @select="closePop"
          >
            <div
              v-for="(item, index) in langList"
              :key="index"
              class="flex-center justify-between bg-white list-warp brd-8"
            >
              <div class="flex-center">
                <img :src="item.img" alt="" class="lang-img" />
                <div>{{ item.lang }}</div>
              </div>
              <van-radio :name="item.id" checked-color="#16eb79"></van-radio>
            </div>
          </van-radio-group>
        </div>
      </div>
    </van-overlay>
    <!-- 游戏tips -->
    <van-overlay :show="isShowGamesTips">
      <div class="wrapper-tips">
        <div class="block">
          <img class="load-img" src="@/assets/image/yazi_jiazai.gif" alt="" />
          <div class="white tips-text">
            {{
              enterGame
                ? $t("message.enterGameTips")
                : $t("message.closeGameTips")
            }}
            <!-- <div v-for="(item, index) in gameTipsList" :key="index">
              {{ item.tips }}
            </div> -->
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {
  banner,
  webLabel,
  webGames,
  webLabelGames,
  webHotGames,
  webRecommendGames,
  refreshBalance,
  tips,
  joinGames,
  exitGames,
} from "@/api/home";
import { user } from "@/api/login";
import common from "@/api/common";
import { getToken } from "@/utils/auth";
export default {
  name: "home-scollbehavior",
  data() {
    return {
      searchGame: "",
      isChangeScroll: false,
      firstImgUrl: require("@/assets/image/yellowHomeBg.png"),
      showPopover: false,
      languageIsShow: false,
      actions: [],
      tabActive: 0,
      chosenLangId: "en",
      langList: common.langList,
      tabList: [],
      searchValue: null,
      bannerList: [],
      bannerParams: {
        types: 1,
      },
      videoList: [],
      tabGamesList: [],
      hotGameList: [],
      recommendGameList: [],
      queryForm: {
        page: 1,
        limit: 10,
      },
      finished: false, //是否已加载完成，加载完成后不再触发load事件
      loading: false,
      refreshing: false,
      allGameList: [],
      queryLabelForm: {
        page: 1,
        limit: 10,
      },
      labelFinished: false, //是否已加载完成，加载完成后不再触发load事件
      labelLoading: false,
      labelRefreshing: false,
      labelTotal: 0,
      labelGameList: [],
      isShowGamesTips: false,
      enterGame: true,
      gameTipsList: [],
      userInfo: { ...common.userInfo },
      bgHeight: "370",
      addRotate: false,
    };
  },
  mounted() {
    this.searchGame = `${this.$t("message.searchGame")}`;
    this.getWebLabel();
    getToken() ? this.getUserInfo() : "";
    localStorage.removeItem("gameData");
    localStorage.removeItem("payData");
    localStorage.removeItem("gamePath");
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path === "/games") {
        vm.gamesOut();
      }
    });
  },
  methods: {
    // 以下为逻辑方法
    isLogoin() {
      getToken()
        ? this.$router.push("./recharge")
        : this.$router.push("./login");
    },
    stickyTop(e) {
      e.isFixed
        ? (this.bgHeight = e.scrollTop <= 150 ? 370 - e.scrollTop : 370)
        : (this.bgHeight = e.scrollTop + 370);
    },
    clickPop() {
      this.actions = [
        // {
        //   text: "Language",
        //   icon: require("@/assets/image/icon_yy.png"),
        //   type: "Lang",
        // },
        {
          text: `${this.$t("message.withdrawDeposit")}`,
          icon: require("@/assets/image/cardRecive.png"),
          type: "Deposit",
        },
      ];
      getToken()
        ? this.actions.push({
            text: `${this.$t("message.quit")}`,
            icon: require("@/assets/image/arrowsALogout.png"),
            type: "Lagout",
          })
        : "";
      this.showPopover = !this.showPopover;
    },
    selectPopover(e) {
      this.showPopover = false;
      this.languageIsShow = e.type === "Lang";
      if (e.type === "Deposit") {
        getToken()
          ? this.$router.push("./withdraw")
          : this.$router.push("./login");
      }
      if (e.type === "Lagout" && getToken()) {
        this.$store.dispatch("logSignOut").then(() => {
          this.$router.go(0);
        });
      }
    },
    changeLang(e) {
      this.chosenLangId = e;
      sessionStorage.setItem("locale", e);
      this.$i18n.locale = e;
      this.languageIsShow = false;
      this.getWebLabel();
    },
    closePop() {
      this.languageIsShow = false;
    },
    getUserInfo() {
      user().then((res) => {
        this.userInfo = res.data;
        this.userInfo.balance = res.data.balance.toFixed(2);
      });
    },
    // 接口信息处理
    async getWebLabel() {
      const res = await webLabel();
      if (res) {
        if (res.data && res.data.length > 0) {
          res.data.unshift({
            label_id: 0,
            label_name: this.$t("message.title"),
          });
          this.tabList = res.data;
        }
      }
      this.getBanner();
      this.getVideo();
      this.getWebGames();
      this.getHotGame();
      this.getRecommendGame();
      this.getGamesTips();
    },
    tabChange(e) {
      this.tabActive = e.name;
      if (this.tabActive !== 0) {
        this.labelGameList = [];
        this.queryLabelForm["label_id"] = e.name;
        this.queryLabelForm.page = 1;
        this.getWebLabelGames();
      } else {
        this.onRefresh();
      }
    },
    getWebLabelGames() {
      webLabelGames(this.queryLabelForm).then((res) => {
        this.labelTotal = res.data.total;
        if (res.data && res.data.list) {
          if (this.labelRefreshing) {
            (this.labelGameList = []), (this.labelRefreshing = false);
          }
          if (this.queryLabelForm.page === 1) {
            this.labelGameList = res.data.list;
          } else {
            this.labelGameList = [...this.labelGameList, ...res.data.list];
          }
          this.labelLoading = false;
        } else {
          // 数据如果没有返回直接加载完成
          this.labelFinished = true;
        }
      });
    },
    //上拉加载
    onLoadLabel() {
      if (
        this.queryLabelForm.limit * this.queryLabelForm.page <
        this.labelTotal
      ) {
        // 加载结束不再出触发滚动条
        this.labelFinished = false;
        this.queryLabelForm.page++;
        this.getWebLabelGames();
      }
    },
    onRefreshLabel() {
      this.queryLabelForm.page = 1;
      this.labelLoading = true;
      this.labelFinished = false;
      this.getWebLabelGames();
    },
    getBanner() {
      this.bannerParams["typef"] = 1;
      this.bannerParams["location"] = 1;
      banner(this.bannerParams).then((res) => {
        this.bannerList = res.data;
      });
    },
    getVideo() {
      this.bannerParams["typef"] = 2;
      this.bannerParams["location"] = 2;
      banner(this.bannerParams).then((res) => {
        this.videoList = res.data;
      });
    },
    refreshAnimate() {
      this.addRotate = true;
      setTimeout(() => {
        this.addRotate = false;
      }, 8000);
    },
    // 刷新余额
    refresh() {
      this.addRotate = true;
      if (getToken()) {
        this.refreshAnimate();
        const now = new Date().getTime();
        const lastFetchTime = localStorage.getItem("lastFetchTime");
        if (!lastFetchTime || now - lastFetchTime > 5 * 60 * 1000) {
          // 处理响应数据
          refreshBalance().then((res) => {
            this.userInfo.balance =
              res.data && res.data.balance
                ? res.data.balance.toFixed(2)
                : "0.00";
          });
          localStorage.setItem("lastFetchTime", now.toString());
        }
      } else {
        this.$router.push("./login");
      }
    },
    // 游戏列表
    async getWebGames() {
      let res = await webGames({ ...this.queryForm });
      this.loading = false; //加载结束
      if ((res.data.list ?? "") !== "") {
        if (this.refreshing) {
          this.allGameList = [];
          this.refreshing = false;
        }
        if (this.queryForm.page === 1) {
          this.allGameList = res.data.list;
        } else {
          this.allGameList = [...this.allGameList, ...res.data.list];
        }
        if (this.allGameList.length == res.data.total) {
          this.finished = true;
        } else {
          this.finished = false;
        }
      }
    },
    //上拉加载
    async onLoad() {
      this.queryForm.page++;
      await this.getWebGames();
    },
    //下拉加载
    onRefresh() {
      this.queryForm.page = 1;
      this.getWebGames();
    },
    // 热门游戏
    getHotGame() {
      webHotGames().then((res) => {
        this.hotGameList = res.data;
      });
    },
    // 推荐游戏
    getRecommendGame() {
      webRecommendGames().then((res) => {
        this.recommendGameList = res.data;
      });
    },
    // 游戏加载提示
    getGamesTips() {
      tips().then((res) => {
        this.gameTipsList = res.data;
      });
    },
    // 进入游戏
    openPlayGame(item) {
      if (!getToken()) {
        return this.$router.push("./login");
      }
      this.isShowGamesTips = true;
      this.enterGame = true;
      joinGames({ game_id: item.game_id }).then((res) => {
        this.isShowGamesTips = false;
        this.enterGame = false;
        if (res.code === 200) {
          localStorage.setItem("gameId", item.game_id);
          localStorage.setItem("gameData", JSON.stringify(res.data));
          this.$router.push("./games");
          this.getUserInfo();
          if (res.data.types === 2) {
            setTimeout(() => {
              var newWin = window.open("", "_self");
              newWin.document.write(res.data.html);
              newWin.document.close();
            }, 300);
          }
        }
      });
    },
    // 退出游戏
    gamesOut() {
      this.isShowGamesTips = true;
      this.enterGame = false;
      const gameId = localStorage.getItem("gameId");
      exitGames({ game_id: gameId }).then(() => {
        this.getUserInfo();
        this.isShowGamesTips = false;
        this.enterGame = true;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/* 媒体查询 */
@media (min-width: 768px) {
  /* PC样式 */
  .popModule {
    left: 278px;
  }
  .other-tab {
    min-height: 400px;
  }
  .exitMain {
    min-height: 400px;
  }
  ::v-deep .van-overlay {
    max-width: 468px !important;
    top: 0% !important;
    left: auto !important;
  }
}

@media (max-width: 767px) {
  /* 移动端样式 */
  .popModule {
    right: 10px;
  }
  .exitMain {
    height: 100vh;
  }
}
@keyframes fadenum12 {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

::v-deep .van-popover__arrow {
  display: none;
}
::v-deep .van-tabs__nav {
  background: none !important;
}
::v-deep .van-tabs__line {
  background: none !important;
}
::v-deep .van-tab--active {
  color: #fff !important;
  font-size: 18px;
}
::v-deep .van-tab {
  color: rgba(255, 255, 255, 0.7);
}
::v-deep .van-search__content {
  background: none !important;
  padding-left: 2px !important;
}
::v-deep .van-overlay {
  z-index: 99999999;
}
.home {
  width: 100%;
  .bg-home {
    background: url("@/assets/image/yellowHomeBg.png") no-repeat center;
    background: url("@/assets/image/yellowHomeBg.png");
    background-size: cover;
    .tab-fixed-head {
      background: url("@/assets/image/yellowHomeBg.png");
      background-size: cover;
      height: 150px;
      position: relative;
      ::v-deep .van-tab {
        padding-left: 0 !important;
        padding-right: 30px !important;
      }
      .info {
        margin-left: 20px;
        .money {
          margin-top: 8px;
          .rotate {
            transform: rotate(360deg);
            transition: transform 1s ease;
          }
        }
        p {
          font-size: 14px;
          font-weight: 400;
        }
        .coin {
          padding: 0px 8px 0 3px;
        }
      }
    }
    .nav-index {
      padding: 30px 10px 15px 10px;
    }
  }
  .popModule {
    position: absolute;
    z-index: 9999;
    top: 90px;
    background: rgba(251, 249, 249, 0.9);
    color: #4b4b4b;
    padding: 10px 15px;
    border-radius: 10px;
    .pop {
      display: flex;
      flex-direction: column;
      .pop-tag {
        padding: 5px 0;
        .text {
          margin-left: 15px;
          font-size: 12px;
          line-height: 26px;
        }
      }
      .more-line {
        max-width: 150px;
      }
    }
  }
  .search {
    border-radius: 4px;
    height: 43px;
    .van-field__control {
      font-size: 14px;
      line-height: 43px;
    }
    .icon-search {
      margin-top: 6px;
    }
  }
  .my-swipe {
    margin-top: 15px;
    border-radius: 8px;
    height: 168px;
    .van-swipe-item {
      color: #fff;
      font-size: 20px;
      text-align: center;
      width: 100%;
      img {
        height: 168px;
        width: 100%;
        border-radius: 8px;
      }
    }
  }
  .tab-main {
    padding-top: 20px !important;
  }
  .content {
    padding: 0 10px;
    margin-top: -215px;
    .vivoe-bg {
      // height: 168px;
      width: 100% !important;
    }
    .game-warp {
      padding: 10px 0;
      border-bottom: 1px solid #efeeee;
      // &:last-child {
      //   border-bottom: none;
      // }
      .play-btn {
        padding: 4px 18px;
      }
    }
    .tab-list {
      padding-bottom: 10px;
    }
    .swipe-slot {
      .slide-wrap {
        width: 100%;
        margin: 15px 0;
        .empty {
          width: 100%;
          min-height: 110px;
        }
        .tags {
          min-height: 110px;
          display: flex;
          overflow: auto;
          // padding: 10px 0 10px 0;
          white-space: nowrap;
          background: red;
          background: #fff;
          .tag {
            display: flex;
            flex-direction: column;
            align-items: center;
            vertical-align: middle;
            padding-right: 16px;
            &:last-child {
              padding-right: 0 !important;
            }
          }
          .play-btn {
            padding: 2px 10px;
          }
          .tag-text {
            font-size: 12px;
            line-height: 24px;
            width: 55px;
            color: #343434;
            text-align: center;
          }
        }
        //隐藏滚动条
        .tags::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
  // 语言遮罩层样式
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .block {
      width: 88%;
      padding: 25px 10px;
      background-color: #efeeee;
      .list-warp {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0px !important;
        }
        padding: 10px;
      }
      .lang-img {
        margin-right: 30px;
      }
    }
  }
}
</style>
