import { createApp } from "vue";
import App from "./App.vue";
import i18n from "./i18n/index";
import router from "./router";
import store from "./store";
import Vant from "vant";
import "vant/lib/index.css";
import "@/assets/common.scss";

createApp(App).use(store).use(router).use(Vant).use(i18n).mount("#app");
