export default {
  message: {
    signIn: "दाखिल करना",
    phoneNumber: "फ़ोन नंबर",
    password: "पासवर्ड",
    register: "पंजीकरण करवाना",
    graphCode: "ग्राफ़ सत्यापन कोड",
    SIGNIN: "दाखिल करना",
    loginMethods: "अन्य लॉगिन विधियाँ",
    placeholderCode: "कोड",
    placeholderNumber: "कृपया अपना फोन नंबर दर्ज करें",
    placeholderPassword: "अपना पासवर्ड दर्ज करें",
    getCode: "कोड प्राप्त करें",
    passwordConfirm: "पासवर्ड की पुष्टि",
    // 首页
    rechargeRecord: "रिचार्ज रिकॉर्ड",
    topUp: "लबालब भरना",
    withdrawRecord: "निकासी रिकॉर्ड",
    withdrawDeposit: "निकासी जमा",
    quit: "छोड़ना",
    myName: "मेरा नाम",
    rechargeAmount: "पुनर्भरण राशि",
    channelDisbursement: "संवितरण का चैनल",
    cashAmount: "नकद निकासी राशि",
    bankInformation: "बैंक संबंधी जानकारी",
    amend: "सुधार करना",
    commission: "निकासी कमीशन",
    bankName: "बैंक का नाम",
    bankCard: "बैंक कार्ड",
    bank: "बैंक कार्ड की जानकारी",
    account: "खाता",
    bankCardName: "बैंक कार्ड का नाम",
    cardNo: "कार्ड नंबर",
    email: "ईमेल",
    firstName: "पहला नाम",
    lastName: "उपनाम",
    name: "नाम",
    phone: "फ़ोन",
    upi: "है मैं",
    ok: "ठीक है",
    enterAccount: "खाता दर्ज करें",
    enterCardName: "कार्ड का नाम दर्ज करें",
    enterCardNo: "कार्ड नंबर दर्ज करें",
    enterEmail: "ईमेल दर्ज करें",
    enterFirstName: "प्रथम नाम दर्ज करें",
    enterLastName: "अंतिम नाम दर्ज करो",
    enterName: "नाम दर्ज करें",
    enterPhone: "कृपया अपना फोन नंबर दर्ज करें",
    inputUpi: "इनपुट Upi",
    //
    searchGame: "खोज खेल",
    title: "सभी खेल",
    paly: "खेल",
    login_14: "दो पासवर्ड मेल नहीं खाते",
    enterGameTips: "खेल लोडिंग, कृपया कार्य नहीं करें",
    closeGameTips: "खेल से बाहर निकले, कृपया कार्य नहीं करें",
    withdrawal_6: "निकासी आवेदन सफल रहा",
    withdrawal_99: "पूर्ण आवेदन",
    withdrawal_0: "समीक्षाधीन",
    withdrawal_1: "जारी किया जाने के लिए",
    withdrawal_2: "जारी किए गए",
    withdrawal_3: "प्रावधान विफलता",
    withdrawal_4: "मूल रास्ते पर लौटें",
    withdrawal_5: "बंद करना",
    pay_0: "सभी आदेश",
    pay_1: "भुगतान के दौरान",
    pay_2: "भुगतान की सफलता",
    pay_3: "भुगतान विफलता",
    pay_4: "समय समाप्ति बंद",
    failed: "लोडिंग असफल",
    googleLoginTips: "लॉगिंग",
    popular: "लोकप्रिय खेल",
    recommendation: "अनुशंसित खेल",
  },
};
