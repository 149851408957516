<template>
  <div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view
    ></keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<style>
#app {
  font-family: "Open-Sans";
  width: 100vw;
}
/* 媒体查询 */
@media (min-width: 768px) {
  /* PC样式 */
  #app {
    font-family: "Open-Sans";
    max-width: 468px;
    margin: 0 auto;
    cursor: pointer;
    /* .van-overlay {
      max-width: 468px !important;
    } */
  }
}

@media (max-width: 767px) {
  /* 移动端样式 */
  #app {
    font-family: "Open-Sans";
    width: 100vw;
    .van-overlay {
      max-width: 100vw;
    }
  }
}
</style>
