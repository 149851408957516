export default {
  message: {
    title: "Todos os jogos",
    paly: "Jogar",
    login_14: "Duas senhas não coincidem",
    enterGameTips: "Carregamento do jogo, por favor não opere",
    closeGameTips: "A sair do jogo, por favor não opere",
    withdrawal_6: "O pedido de saque foi bem-sucedido",
    withdrawal_99: "Aplicação completa",
    withdrawal_0: "A ser revisado",
    withdrawal_1: "A ser emitido",
    withdrawal_2: "Publicado",
    withdrawal_3: "Falha no provisionamento",
    withdrawal_4: "voltar ao caminho original",
    withdrawal_5: "fechar",
    pay_0: "todos os pedidos",
    pay_1: "durante o pagamento",
    pay_2: "sucesso no pagamento",
    pay_3: "falha no pagamento",
    pay_4: "Tempo limite próximo",
    failed: "O carregamento falhou",
    googleLoginTips: "Entrar",
    popular: "Jogos Populares",
    recommendation: "Jogos recomendados",
  },
};
