import axios from "axios";
import router from "@/router";
import { showToast } from "vant";
import {
  getToken,
  getGuid,
  getTime,
  generateRandomString,
  removeToken,
  removeGuid,
} from "@/utils/auth";
const service = axios.create({
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
  timeout: 50000,
});
// request拦截器
service.interceptors.request.use(
  (config) => {
    (config.headers.Language = sessionStorage.getItem("locale") || "hi"),
      (config.headers.License = window.parameter.License || "VoJNbY6OzKG"),
      (config.headers.Guid = getGuid()),
      (config.headers["Access-Token"] = getToken()),
      (config.baseURL = "//ujoy.y3ex.com");
    if (config.url !== "google/callback") {
      if (config.method === "post") {
        config.data = JSON.stringify({
          nonce: generateRandomString(32),
          timestamp: getTime(),
          ...config.data,
        });
      } else if (config.method === "get") {
        config.params = {
          nonce: generateRandomString(32),
          timestamp: getTime(),
          ...config.params,
        };
      }
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
  async (response) => {
    const data = response.data;
    if (data.code == 3002) {
      removeToken();
      removeGuid();
      localStorage.removeItem("gameData");
      localStorage.removeItem("game_id");
      localStorage.removeItem("callbackHTML");
      router.push("./login");
    } else if (data.code !== 200) {
      showToast({
        message: `${data.message ? data.message : "Network Error"}`,
      });
      // return Promise.reject(new Error(data.message));
    }
    return Promise.resolve(data);
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
