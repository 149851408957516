import request from "@/utils/request";

export function banner(params) {
  return request.get("web/v1/banner", { params });
}

export function webLabel(params) {
  return request.get("web/v1/label", { params });
}

export function webGames(params) {
  return request.get("web/v1/games", { params });
}

export function webLabelGames(params) {
  return request.get("web/v1/label/games", { params });
}

export function webHotGames(params) {
  return request.get("web/v1/game/hot", { params });
}

export function webRecommendGames(params) {
  return request.get("web/v1/game/recommend", { params });
}

export function refreshBalance(data) {
  return request.post("web/v1/refresh/balance", data);
}

export function tips(params) {
  return request.get("web/v1/tips", { params });
}

export function joinGames(data) {
  return request.post("web/v1/join/game", data);
}

export function exitGames(data) {
  return request.post("web/v1/exit/game", data);
}
