export default {
  message: {
    title: "Todo el juego",
    paly: "Jugar",
    login_14: "Dos contraseñas no coinciden",
    enterGameTips: "En la carga del juego, no opere",
    closeGameTips: "Juego de salida, por favor no opere",
    withdrawal_6: "La solicitud de retiro fue exitosa.",
    withdrawal_99: "Solicitud completa",
    withdrawal_0: "Para ser revisado",
    withdrawal_1: "Ser tramitado",
    withdrawal_2: "Emitido",
    withdrawal_3: "Fallo de aprovisionamiento",
    withdrawal_4: "volver a la forma original",
    withdrawal_5: "cerca",
    pay_0: "todas las órdenes",
    pay_1: "durante el pago",
    pay_2: "pago exitoso",
    pay_3: "fallo de pago",
    pay_4: "Cierre de tiempo de espera",
    failed: "Falló la carga",
    googleLoginTips: "Inicio de sesión",
    popular: "Juegos populares",
    recommendation: "Juegos recomendados",
  },
};
