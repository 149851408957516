import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "",
      keepAlive: true,
    },
  },
  {
    name: "login",
    path: "/login",
    component: () => import("@/views/login/LoginView.vue"),
    meta: {
      title: "",
      keepAlive: true,
    },
  },
  {
    name: "register",
    path: "/register",
    component: () => import("@/views/register/RegisterView.vue"),
    meta: {
      title: "",
      keepAlive: true,
    },
  },
  {
    name: "withdraw",
    path: "/withdraw",
    component: () => import("@/views/withdraw/WithdrawView.vue"),
    meta: {
      titile: "",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    name: "RecordView",
    path: "/RecordView",
    component: () => import("@/views/withdraw/RecordView.vue"),
    meta: {
      title: "",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    name: "DepositView",
    path: "/DepositView",
    component: () => import("@/views/withdraw/DepositView.vue"),
    meta: {
      title: "",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    name: "recharge",
    path: "/recharge",
    component: () => import("@/views/recharge/RechargeView.vue"),
    meta: {
      titile: "",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    name: "RecordIndex",
    path: "/RecordIndex",
    component: () => import("@/views/recharge/RecordIndex.vue"),
    meta: {
      title: "",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    name: "Games",
    path: "/games",
    component: () => import("@/views/games/gameIndex.vue"),
    meta: {
      title: "",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    name: "Middle",
    path: "/middle",
    component: () => import("@/views/middle/middlePage.vue"),
    meta: {
      title: "",
      keepAlive: true,
      requireAuth: true,
    },
  },
  {
    name: "GoogleLogin",
    path: "/google/web/callback",
    component: () => import("@/views/googleLogin/googleView.vue"),
    meta: {
      title: "",
      keepAlive: true,
    },
  },
  {
    name: "telegram",
    path: "/telegram",
    component: () => import("@/views/telegram/telegramLogin.vue"),
    meta: {
      titile: "",
      keepAlive: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    if (localStorage.getItem("accessToken")) {
      next();
    } else {
      next({
        path: "/login",
      });
    }
  } else {
    next();
  }
});
export default router;
