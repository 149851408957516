export default {
  message: {
    title: "すべてのゲーム",
    paly: "놀다",
    login_14: "두 개의 비밀번호가 일치하지 않습니다",
    enterGameTips: "게임 로드 중, 조작하지 마십시오",
    closeGameTips: "게임을 종료하는 중입니다. 하지 마십시오.",
    withdrawal_6: "출금신청이 성공하였습니다",
    withdrawal_99: "전체 적용",
    withdrawal_0: "검토 예정",
    withdrawal_1: "발행예정",
    withdrawal_2: "발행 된",
    withdrawal_3: "프로비저닝 실패",
    withdrawal_4: "원래의 방식으로 돌아가다",
    withdrawal_5: "닫다",
    pay_0: "모든 주문",
    pay_1: "결제 중",
    pay_2: "결제 성공",
    pay_3: "결제 실패",
    pay_4: "시간 초과 종료",
    failed: "로드 실패",
    googleLoginTips: "로그인 중",
    popular: "T인기 게임",
    recommendation: "추천 게임",
  },
};
