export default {
  message: {
    title: "Tất cả các trận đấu",
    paly: "Chơi",
    login_14: "Hai mật khẩu không khớp",
    enterGameTips: "Đang tải game, không hoạt động",
    closeGameTips: "Thoát khỏi trò chơi, không hoạt động",
    withdrawal_6: "Ứng dụng rút tiền đã thành công",
    withdrawal_99: "Ứng dụng đầy đủ",
    withdrawal_0: "Để được xem xét",
    withdrawal_1: "Sẽ được phát hành",
    withdrawal_2: "Cấp",
    withdrawal_3: "Lỗi cấp phép",
    withdrawal_4: "trở lại con đường ban đầu",
    withdrawal_5: "đóng",
    pay_0: "tất cả các đơn đặt hàng",
    pay_1: "trong quá trình thanh toán",
    pay_2: "Thanh toán thành công",
    pay_3: "thanh toán thất bại",
    pay_4: "Đã hết thời gian chờ",
    failed: "Tải không thành công",
    googleLoginTips: "Đăng nhập",
    popular: "Trò chơi phổ biến",
    recommendation: "Trò chơi được đề xuất",
  },
};
