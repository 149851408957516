import { createStore } from "vuex";
import { signin, signup, signout } from "@/api/login";
import {
  getToken,
  removeToken,
  setToken,
  setGuid,
  getGuid,
  removeGuid,
} from "@/utils/auth";
export default createStore({
  namespaced: true,
  state: {
    token: getToken(),
    guid: getGuid(),
  },
  getters: {},
  mutations: {
    set_token: (state, token) => {
      state.token = token;
      setToken(token);
    },
    set_guid: (state, guid) => {
      state.guid = guid;
      setGuid(guid);
    },
  },
  actions: {
    // 登录
    userLogin({ commit }, loginInfo) {
      return new Promise((resolve, reject) => {
        signin({ ...loginInfo })
          .then((response) => {
            const { data, code } = response;
            if (code === 200) {
              commit("set_token", data.access_token);
              commit("set_guid", data.guid);
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    //注册
    signup({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        signup({ ...userInfo })
          .then((response) => {
            const { data, code } = response;
            if (code === 200) {
              commit("set_token", data.access_token);
              commit("set_guid", data.guid);
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 登出
    logSignOut({ commit }) {
      return new Promise((resolve, reject) => {
        signout()
          .then(() => {
            commit("set_token", "");
            removeToken();
            commit("set_guid", "");
            removeGuid();
            localStorage.removeItem("gameData");
            localStorage.removeItem("gameId");
            localStorage.removeItem("payData");
            localStorage.removeItem("gamePath");
            localStorage.removeItem("callbackHTML");
            localStorage.removeItem("lastFetchTime");
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 清除token
    resetToken({ commit }) {
      return new Promise((resolve) => {
        commit("set_token", "");
        removeToken();
        resolve();
      });
    },
    // 清除guid
    resetGuid({ commit }) {
      return new Promise((resolve) => {
        commit("set_guid", "");
        removeGuid();
        resolve();
      });
    },
  },
  modules: {},
});
