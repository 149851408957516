//设置token
export function setToken(token) {
  return localStorage.setItem("accessToken", token);
}
export function getToken() {
  return localStorage.getItem("accessToken");
}
export function removeToken() {
  return localStorage.removeItem("accessToken");
}
export function setGuid(guid) {
  return localStorage.setItem("guid", guid);
}
export function getGuid() {
  return localStorage.getItem("guid");
}
export function removeGuid() {
  return localStorage.removeItem("guid");
}
export function getTime() {
  return Math.floor(new Date().getTime() / 1000);
}

export function generateRandomString(length) {
  let result = "";
  const characters = "0123456789abcdefghijklmnopqrstuvwxyz";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}
