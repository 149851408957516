const common = {
  optionNumber: [
    {
      num: "86",
      img: require("@/assets/image/cn_icon.png"),
    },
    {
      num: "01",
      img: require("@/assets/image/icon_mg.png"),
    },
    {
      num: "84",
      img: require("@/assets/image/icon_yn.png"),
    },
    {
      num: "91",
      img: require("@/assets/image/icon_yd.png"),
    },
    {
      num: "55",
      img: require("@/assets/image/icon_bx.png"),
    },
    {
      num: "52",
      img: require("@/assets/image/icon_mxg.png"),
    },
    {
      num: "81",
      img: require("@/assets/image/icon_rb.png"),
    },
    {
      num: "82",
      img: require("@/assets/image/icon_hg.png"),
    },
  ],
  langList: [
    {
      id: "en",
      lang: "English",
      img: require("@/assets/image/icon_mg.png"),
    },
    {
      id: "vi",
      lang: "Tiếng Việt",
      img: require("@/assets/image/icon_yn.png"),
    },
    {
      id: "hi",
      lang: "हिंदी",
      img: require("@/assets/image/icon_yd.png"),
    },
    {
      id: "pt_br",
      lang: "Português",
      img: require("@/assets/image/icon_bx.png"),
    },
    {
      id: "es_mx",
      lang: "español",
      img: require("@/assets/image/icon_mxg.png"),
    },
    {
      id: "ja",
      lang: "日本語",
      img: require("@/assets/image/icon_rb.png"),
    },
    {
      id: "ko",
      lang: "한국인",
      img: require("@/assets/image/icon_hg.png"),
    },
  ],
  accountList: [
    {
      id: 1,
      moneyCion: 100,
    },
    {
      id: 2,
      moneyCion: 500,
    },
    {
      id: 3,
      moneyCion: 1000,
    },
    {
      id: 4,
      moneyCion: 2000,
    },
    {
      id: 5,
      moneyCion: 5000,
    },
    {
      id: 6,
      moneyCion: 10000,
    },
  ],
  userInfo: {
    nickname: "",
    balance: "0.00",
    head_img_url: require("../assets/image/morentouxiang_moren.png"),
  },
  // tabWithdrawList: [
  //   {
  //     labelName: `${this.$t("message.withdrawal_99")}`,
  //     labelType: 99,
  //   },
  //   {
  //     labelName: `${this.$t("message.withdrawal_0")}`,
  //     labelType: 1,
  //   },
  // ],
};

export default common;
